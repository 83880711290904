<template>
    <!-- Begin Page Content -->
    <div class="container-fluid">

        <!-- Page Heading -->
        <h1 class="h3 mb-4 text-gray-800">Blank Page</h1>

    </div>
    <!-- /.container-fluid -->
</template>

<script>
export default {
  name: 'Blank',
}
</script>